<template>
  <div>
    <b-container>
      <b-card class="my-4 pb-0">
        <b-row>
          <b-col md="9">
            <div class="mt-4 mb-3 header-horizontal-title position-relative">
              <h4 class="font-weight-bold position-relative text-primary py-1">
                {{videosInfo.title}}
              </h4>
            </div>
            <video autoplay controls class="w-100 bg-warning-light" height="500" id="myVideo" v-if="!loadingProductInfo">
              <source :src="videosInfo.gallery_media[0].url" type="video/mp4">
            </video>
            <div>
            <p class="font-size-18 text-justify mt-4 my-3 text-black">{{videosInfo.content}}</p>
            </div>
            <div class="d-flex justify-content-around align-items-center mt-5">
              <b-button
                  v-if="videosInfo.prev"
                  @click="$router.push({ name: 'videoDetails', params: { videoId: videosInfo.prev.id } })"
                  variant="outline-primary" class="py-2 px-4 iq-border-radius-5">
                <span><i class="las la-angle-double-right"></i></span>
                <span>الخبر السابق</span>
              </b-button>
              <b-button
                  v-if="videosInfo.next"
                  @click="$router.push({ name: 'videoDetails', params: { videoId: videosInfo.next.id } })"
                  variant="primary" class="py-2 px-4 iq-border-radius-5">
                <span>الخبر التالى</span>
                <span><i class="las la-angle-double-left"></i></span>
              </b-button>
            </div>
          </b-col>
          <b-col md="3" class="mt-4">
            <div>
              <h4 class="text-primary mb-4">{{ $t('endUser.videos') }} الأكثر انتشارا</h4>
              <div class="border-1" v-for="(item ,key) in mostVideosData" :key="key">
                <router-link :to="{name:'videoDetails', params:{videoId: item.id}}">
                  <video controls class="w-100 bg-warning-light" height="180" id="myVideo">
                    <source :src="item.featured_image" type="video/mp4">
                  </video>
<!--                  <img :src="item.featured_image" class="w-100">-->
                  <!--                <section v-if="type !== 'video'" :style="{-->
                  <!--              'background-size': 'cover',-->
                  <!--              'background-position': 'center',-->
                  <!--              'background-image':-->
                  <!--           'url(' + item.featured_image + ')' }"-->
                  <!--                         class="w-100 h-250px position-relative cursor-pointer">-->
                  <!--                  <div class="overlay-image" v-if="type ==='image'">-->
                  <!--                    +{{ item.media_count }} صوره-->
                  <!--                  </div>-->
                  <!--                </section>-->
                  <!--                  <iframe style="width: 100%" height="200" src="https://www.youtube-nocookie.com/embed/gEDcKhhxUZU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                  <h5 class="py-2">{{ item.title }}</h5>
                  <p>{{item.content.length > 37 ? item.content.substring(0, 37) + '...' : item.content}}</p>
                </router-link>
                <!--              <div>-->
<!--                                <video controls class="w-100 bg-warning-light" height="180" id="myVideo">-->
<!--                                  <source src="https://ibbil.dafa.dev/videos/12.mp4" type="video/mp4">-->
<!--                                </video>-->
                <!--                <h5>الحفل الختامي لمهرجان الملك عبدالعزيز</h5>-->
                <!--                <p>-->
                <!--                  صصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح،-->
                <!--                </p>-->
                <!--              </div>-->
                <!--              <div>-->
                <!--                <video controls class="w-100 bg-warning-light" height="180" id="myVideo">-->
                <!--                  <source src="https://ibbil.dafa.dev/videos/7.mp4" type="video/mp4">-->
                <!--                </video>-->
                <!--                <h5>الحفل الختامي لمهرجان الملك عبدالعزيز</h5>-->
                <!--                <p>-->
                <!--                  صصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح،-->
                <!--                </p>-->
                <!--              </div>-->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import homeServices from '@/modules/primaryPages/home/services/home'

export default {
  name: 'eventDetails',
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingProductInfo: false,
      videosInfo: [],
      mostVideosData: []
    }
  },
  watch: {
    '$route' () {
      // this.$nextTick(() => {
      //   this.msg = 'Three'
      // })
      this.getVideoDetails()
    }
  },
  methods: {
    getVideoDetails () {
      this.loadingProductInfo = true
      homeServices.getVideoDetails(this.$route.params.videoId).then(res => {
        this.videosInfo = res.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    },
    mostVideos () {
      this.loadingProductInfo = true
      homeServices.getMostVideos().then(res => {
        this.mostVideosData = res.data.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    }
  },
  created () {
    this.getVideoDetails()
    this.mostVideos()
  }
}
</script>
